import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import InDialog from "src/ui/components/InDialog/InDialog";
import WeightForm from "src/ui/components/WeightForm/WeightForm";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";

const WeightDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  return (
    <InDialog
      popup
      title={translate("upload_labs.title")}
      returnUrl={returnUrl}
      onClose={onClose}
      setRef={setModalRef}
    >
      <nine-spacer s="md"></nine-spacer>

      <WeightForm onComplete={onClose} />
    </InDialog>
  );
};

export default WeightDialog;
